.input {
  width: 100%;
  height: 38px;
  border: 1px solid #D7D8DF;
  border-radius: 4px;
  padding: 8px 12px;
  outline: none;
  font-size: 14px;
  &:hover {
    border: 1px solid rgba(53, 64, 82, 0.5);
  }
}
