.message-group {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 220px);
  width: 100%;
  padding: 40px;
}

.add-btn {
  right: 16px;
  bottom: 16px;
  position: absolute;
}

.atom-header {
  display: flex;
  align-items: center;
}

.dlt-msg {
  position: absolute;
  cursor: pointer;
  top: -10px;
  right: -20px;
  z-index: 5;
}

.MuiGrid-typeItem-135.MuiGrid-grid-xs-12-171.MuiGrid-grid-sm-8-180 {
  width: 60%;
  max-width: 60%;
}

/*.Select-menu-outer {
    top: auto!important;
    bottom: 100%!important;
}*/

.quick-reply {
  border-radius: 35px;
  color: #2668e8;
  outline: none;
  text-align: center;
  margin: 3px;
  border: 1px solid #2668e8;
  background-color: #ffffff;
  padding: 10px 16px 24px;
  font-size: 14px;
  line-height: 16px;
  resize: none;
  height: 36px;
}

.rep-btns-container {
  background-color: white;
  border-radius: 0 0 10px 10px;
}

.add-button {
  color: #0084ff;
  font-weight: 500;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  margin: auto;
  border-radius: 0 0 10px 10px;
}

.add-reply {
  margin: 0 0 5px;
  min-width: 186px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border: 1px solid #1658f3;
  border-radius: 50px;
  font-size: 14px;
  line-height: 16px;
  height: 38px;
  display: flex;
}

.add-reply-text {
  padding: 10px;
  color: #1658f3;
}

.button {
  width: 100%;
  color: #0084ff;
  font-weight: 500;
  text-align: center;
  height: 36px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  outline: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none !important;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 20px !important;
  height: 20px !important;
}

.rangeslider-horizontal {
  height: 10px !important;
}
