@import "../../../variables";

.button {
  height: 24px;
  border-radius: 4px;
  background-color: $oxford-blue;
  color: $white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 4px;
  cursor: pointer;
  gap: 2px;
}
