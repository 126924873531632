@import "../../../../variables";

.container {
  width: 398px;
}

.header {
  height: 56px;
  border-bottom: 1px solid rgba(53, 64, 82, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.selectButton {
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid $athens-gray;
  color: $comet-blue;
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 12px;
  padding: 5px 7px;
}

.selectButton > span {
  padding-right: 8px;
}

.commentsContainer {
  display: flex;
  flex-direction: column;
  padding: 0 38px;
  height: 440px;
  overflow: auto;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 440px;
  justify-content: center;
}
