@import '../../variables';

.title {
  font-size: 14px;
  font-weight: 400;
  margin: 16px 0 8px;
  color: #3A3F62;
}

.errorMessage {
  font-size: 12px;
  position: absolute;
  color: $persimmon-red;
  margin: 0;
}
