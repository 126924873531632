@import '../../../../variables';

.input {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.color {
  width: 24px;
  height: 24px;
}

.value {
  font-size: 14px;
  color: $comet-blue;
}
