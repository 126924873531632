@import "../../../../variables";

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 25px 40px;
  border-bottom: 1px solid rgba(53, 64, 82, 0.1);
}

.title {
  font-size: 18px;
  color: $fiord-blue;
  margin: 0;
  font-weight: 700;
}

.rateWrap {
  display: inline-flex;
  color: rgba(53, 64, 82, 0.5);
  font-size: 12px;
  margin: 0;
  gap: 10px;
  align-items: baseline;
}

.rateWrap > span {
  font-weight: 700;
  color: $fiord-blue;
  font-size: 14px;
}

.rateWrap > span > span {
  color: $persimmon-red;
}
