@import 'src/variables.scss';

.container {
  position: relative;
}

.id {
  font-size: 12px;
  color: $ribbon-blue;
  position: absolute;
  background-color: $white;
  padding: 0 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
