@import "../../../../variables";

.selectButton {
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid $athens-gray;
  color: $comet-blue;
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 12px;
  padding: 5px 7px;
}

.selectButton > span {
  padding-right: 8px;
}

.popover {
  width: 135px;
  height: 125px;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(19, 69, 186, 0.241904);
  padding: 6px 0;
}

.divider {
  width: 100%;
  border-bottom: 1px solid rgba(53, 64, 82, 0.1);
}
