@import '../../../../variables';

.filterItem {
  height: 28px;
  color: $fiord-blue;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  align-items: center;
}

.filterItem:hover {
  background-color: $athens-gray;
}

.filterItem > span {
  width: 36px;
  text-align: center;
}
