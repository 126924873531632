@import '../../../../variables';

.button {
  cursor: pointer;
  color: $ribbon-blue;
}

.editButton {
  padding: 9px 13px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 160px;
  &:hover {
    background: $hawkes-blue;
  }
}

.deleteButton {
  padding: 9px 13px;
  cursor: pointer;
  font-size: 14px;
  color: $persimmon-red;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 160px;
  &:hover {
    background: $hawkes-blue;
  }
}
