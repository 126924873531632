@import '../../../../variables';

.container {
  width: 360px;
  min-width: 360px;
  height: 540px;
  border-radius:10px;
  overflow: hidden;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
}

.header {
  width: 100%;
  height: 65px;
  padding: 8px 24px 8px 16px;
}

.footer {
  width: 100%;
  height: 65px;
  box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background-color: $white;
}

.body {
  width: 100%;
  height: 410px;
  background-color: $white;
}

.logo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.nameWrap {
  display: flex;
  align-items: center;
}

.name {
  color: $white;
  margin: 0 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputPlaceholder {
  margin: 0;
  font-size: 14px;
  color: $spun-pearl-grey;
}

.messageLogo {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin: 0 8px 0 16px;
  position: absolute;
  left: 0;
}

.row {
  display: flex;
  align-items: flex-end;
  padding-left: 56px;
  position: relative;
  gap: 8px;

  &:last-of-type {
    justify-content: flex-end;
  }
}

.message1 {
  border-radius: 14px 14px 14px 8px;
  background-color: $wild-sand-grey;
  color: $mine-shaft-grey;
  font-size: 14px;
  padding: 5px 9px 15px 14px;
  margin: 16px 18px 4px 0;
}

.message2 {
  border-radius: 8px 14px 14px 14px;
  padding: 10px 9px 10px 14px;
  background-color: $wild-sand-grey;
  color: $mine-shaft-grey;
  font-size: 14px;
  margin: 0 44px 0 0;
}

.quickReply {
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 26px;
  border: 1px solid;
  margin: 16px 0;
}

.arrow {
  position: absolute;
  background-color: $white;
  height: 50px;
  width: 25px;
  top: 10px;
  right: -3px;
  box-shadow: 0 0 20px rgba(51, 51, 51, 0.15);
  border-radius: 50px 0 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reply {
  padding: 10px 14px 10px 10px;
  border-radius: 8px 14px 14px 14px;
  font-size: 14px;
  color: $white;
  margin: 0 16px 0 0;
}
