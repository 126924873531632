@import 'src/variables.scss';

.inputWrap {
  margin-bottom: 32px;
  position: relative;
}

.description {
  font-size: 12px;
  color: $oxford-blue;
  font-family: Lato, sans-serif;
  margin: 4px 0 0;
}

.errorAlert {
  color: $persimmon-red;
  margin-bottom: -10px;
  font-size: 12px;
  position: absolute;
}

.buttonHolder {
}
