@import '../../variables';

.container {
  display: flex;
  align-items: center;
}

.inputTitle {
  font-size: 14px;
  color: $comet-blue;
  font-weight: 400;
  margin: 0;
}
