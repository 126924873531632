@import "../../../../variables";

.container {
  padding: 40px;
  overflow: scroll;
  height: calc(100vh - 250px);
}

.iconWrap {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 0;
  background-color: white;
  border: 1px solid $athens-gray;
  box-shadow: 0 3px 6px $athens-gray;
  padding: 8px 10px;
  border-radius: 50px;
}

.stepWrap {
  position: relative;
}
