@import '../../../../variables';

.body {
  padding:0 40px 50px;
  height: calc(100vh - 300px);
  overflow: auto;
}

.tab {
  color: #354052;
  font-size: 14px;
  padding: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.activeTab {
  color: $ribbon-blue;
  font-size: 14px;
  padding: 4px;
  cursor: pointer;
  border-bottom: 1px solid $ribbon-blue;
  white-space: nowrap;
}

.tabs {
  margin: 20px 24px 10px;
  display: flex;
  gap: 28px;
  position: relative;
}
