@import '../../variables';

.container {
  position: absolute;
  background-color: $white;
  padding: 24px 0 0;
  outline: none;
  width: 600px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: visible;
  max-height: 90vh;
}

.header {
  padding: 0 46px 20px 24px;
}

.closeButton {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.title {
  font-weight: bold;
  font-size: 24px;
  color: $oxford-blue;
  margin: 0;
  word-break: break-all;
}

.footer {
  border-top: 1px solid $ghost-grey;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.buttons {
  display: flex;
  gap: 8px;
}