@import '../../../../variables';

.container {
  position: relative;
}
.statusCode {
  font-size: 16px;
  color: #006401;
  text-align: end;
  margin-bottom: 12px;
}
.tabs {
  display: flex;
  flex-direction: column;
}
.activeTab {
  font-size: 14px;
  color: $ribbon-blue;
  cursor: pointer;
  margin: 4px;
}
.tab {
  font-size: 14px;
  color: $oxford-blue;
  cursor: pointer;
  margin: 4px;
}
.body {
  display: flex;
  gap: 24px;
}
.input {
  border-radius: 10px;
  border: 1px solid $athens-gray;
  flex: 1;
  height: 250px;
  padding: 12px;
  overflow: auto;
}
.body{
  font-size: 12px;
}
