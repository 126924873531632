@import '../../variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-height: 100%;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.09);
  border-radius: 10px;
  flex: 1;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text {
  font-size: 16px;
  line-height: 1;
  font-family: 'Lato', sans-serif;
  margin: 0;
  color: #3a3f62;
}

.statsText {
  font-size: 12px;
  line-height: 1;
  font-family: 'Lato', sans-serif;
  margin: 0;
  color: #3a3f62;
}

.statsNumber {
  font-size: 18px;
  line-height: 1;
  color: #1658f3;
  font-weight: 700;
  margin-left: 8px;
  margin-right: 8px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  margin-right: 16px;
}

.titleWrapper {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.button {
  font-family: 'Lato', sans-serif;
  cursor: pointer;
  font-weight: 400;
  color: #1658f3;
}
