@import "../../../../variables";

.counter {
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.09);
  text-align: center;
  border-radius: 10px;
  min-width: 170px;
}

.value {
  font-size: 24px;
  margin: 8px 0;
  font-weight: 700;
  color: #1658F3;
}

.title {
  font-size: 14px;
  margin: 8px 0;
  font-weight: 400;
  color: #616581;
}
