@import '../../../../variables';

.container {
  width: 410px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  height: calc(100vh - 64px);
}

.navigationContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
}
