.container {
  height: 120px;
  position: relative;
  &:hover {
    .editLink {
      display: block;
      left: 230px;
    }
    .iconsWrap {
      visibility: visible;
    }
  }
}
.input {
  border: 1px solid #e5e5e5;
  height: 120px;
  overflow: scroll;
  font-size: 14px;
  padding: 10px 10px 0;
  outline: none;
  background: #fff;
  cursor: auto;
  & a {
    text-decoration: none;
  }
}
.editLink {
  display: none;
  position: absolute;
  border-radius: 20px;
  padding: 5px 13px 13px 12px;
  height: 40px;
  cursor: pointer;
  top: 42px;
  right: -23;
  z-index: 3;
  width: 40px;
  background: #ffffff;
  border: 1px solid #ebebef;
  box-sizing: border-box;
}
// global in scss
.parameter {
  background: #deeafd;
  border-radius: 3px;
}
.linkEdited {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.iconsWrap {
  position: absolute;
  visibility: hidden;
  z-index: 2;
  bottom: 4px;
  right: 4px;
  display: flex;
  gap: 2px;
}
.icon {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: rgba(53, 64, 82, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
}
