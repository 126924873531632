//colors
$white: #ffffff;
$athens-gray: #ebebef;
$ribbon-blue: #1658f3;
$oxford-blue: #354052;
$persimmon-red: #ff624c;
$raven-grey: #727a86;
$spun-pearl-grey: #b0b2c0;
$comet-blue: #616581;
$mischka-grey: #d7d8df;
$wild-sand-grey: #f5f5f5;
$mine-shaft-grey: #373E41;
$ghost-grey: #c7cad6;
$hawkes-blue: #eef4fe;
$fiord-blue: #3a3f62;
$cornflower-blue: #5a98f7;
