@import "./../../variables";

.container {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  align-items: baseline;
  gap: 40px;
}

