@import "../../../../variables";

.container {
  border-radius: 10px;
  border: 1px solid $athens-gray;
  width: 100%;
  margin-bottom: 10px;
  min-width: 400px;
  &:hover {
    & i {
      display: block;
    }
  }
}

.body {
  padding: 24px;
  min-height: 398px;
}

.label {
  font-size: 14px;
  color: $oxford-blue;
  margin-bottom: 8px;
  & span {
    color: $persimmon-red;
  }
}

.inputsWrap {
  display: flex;
  gap: 8px;
}

.inputWrap {
  width: 100%;
}

.deleteTemplate {
  right: 28px;
  top: -16px;
  position: absolute;
  display: none;
  transition: all 0.5s ease;
}

.deleteIcon {
  background: $white;
  border: 1px solid $athens-gray;
  box-shadow: 0 3px 6px $athens-gray;
  width: 40px;
  height: 40px;
  position: absolute;
  right: -40px;
  display: block;
}
