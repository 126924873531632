@import '../../../../variables';

.container {
  position: absolute;
  background-color: $white;
  padding: 40px 90px;
  outline: none;
  width: 450px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 16px;
  color: $oxford-blue;
  font-weight: 600;
  text-align: center;
}

.closeButton {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.deleteButton {
  font-size: 14px;
  padding: 10px 16px;
  color: $persimmon-red;
  cursor: pointer;
}

.buttonsWrap {
  display: flex;
  gap: 8px;
}
