@import '../../variables';

.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100%;
}

.text {
  font-size: 14px;
  font-family: Lato, sans-serif;
  color: #616581;
  text-align: center;
  margin: 16px 0 0;
}
