@import '../../../../variables';

.snippet {
  padding: 16px 24px;
  background-color: $white;
  border-radius: 10px;
  border: 1px solid $ghost-grey;
  font-size: 14px;
  color: $oxford-blue;
  width: fit-content;
  max-width: 700px;
}
