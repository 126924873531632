@import '../../variables';

.container {
  padding-right: 28px;
  display: flex;
  flex-direction: column;
  min-width: 905px;
}

.containerLoader{
  padding-right: 28px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}

.charts {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chart {
  padding: 40px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
  width: 49%;
  justify-content: flex-start;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    margin-left: 40px;
  }
  &>div:not(.DateControl) {
    min-height: calc(100% - 80px - 70px);
    margin-top: 40px;
  }
}

.chartFull { // unused & was passed to AreaChart as a parameter
  width: 100%;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  margin: auto 0 40px;
  gap: 40px;
}

.flexBlock {
  display: flex;
  gap: 40px;
}

.flexBlockColumn {
  display: flex;
  gap: 40px;
  flex-direction: column;
}

.extendedChartBlock {
  width: 70%;
}

.extendedRightBlock {
  width: 30%;
}

.namedCountersBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.channelsCircleChartBlock {
  padding-left: 40px;
}

.chartsColumn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  flex-grow: 1;
  margin: auto 0 40px;
}

:global .chartjs-size-monitor {
  position: relative;
  max-height: 100%;
}

:global .chartjs-render-monitor {
  margin: 0 auto;
  max-width: 100%;
}
