.gradientBarWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.gradientBar {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  max-width: 340px;
  margin-top: 30px;
}

.gradientBarColumn {
  width: 20%;
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
}

.gradientBarItem {
  height: 10px;
  width: 100%;
  margin-bottom: 8px;
}

.gradientBarItemText {
  color: #616581;
  font-size: 12px;
  line-height: 1;
  font-family: 'Lato', sans-serif;
  text-align: right;
}
